@import url("https://use.fontawesome.com/releases/v5.8.2/css/all.css");

/*
*Removes all default whitespace
*/

* {
  padding: 0;
  margin: 0;
}

/*
*Reset CSS model sizing calculations
*/

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  font-family: Arial, sans-serif;
}

.body {
  background: #EAEAEA;
  margin: 0 auto 0 auto;
}

button:hover:disabled {
  cursor: not-allowed;
}

.btn {
  width: 100px;
  padding: 10px;
  border-radius: 8px;
  border: none;
  text-align: center;
  margin: 5px 10px;
}

.btn:hover {
  color: white;
}

.btn-add {
border: 1px solid green;
background: white;
color: green;

}

.btn-add:hover {
  background: green;
  cursor: pointer;
  box-shadow: 0px 0px 30px 2px green;
}

.btn-cancel {
  border: 1px solid orange;
  background: white;
  color: orange;
}

.btn-cancel:hover {
  background: orange;
  cursor: pointer;
box-shadow: 0px 0px 30px 2px orange;
}

.btn-primary {
  border: 1px solid blue;
  background: white;
  color: blue;
  }
  
  .btn-primary:hover {
    background: blue;
    cursor: pointer;
    box-shadow: 0px 0px 30px 2px blue;
  }

.btn-danger {
  border: 1px solid red;
  background: white;
  color: red;  
}
.btn-danger:hover {
  background: red;
  cursor: pointer;
  box-shadow: 0px 0px 30px 2px red;
}

p.error {
  border-radius: 0.3em;
  color: red;
  padding-top: 0;
  margin-bottom: 20px;
}

img {
  box-shadow: 0px 0px 14px 12px #EDEFF0
}

input {
  padding: 16px;
}

label {
  font-size: 14px;
  opacity: 0.8;
}

.flex {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.flex-grow {
  flex-grow: 1;
}